.dropzone{
    margin-bottom: 15px;
}

.img_preview {
    vertical-align: middle;
    height: 98px;
    margin-top: 20px;
}
.preview-box {
  display:flex;
  align-items:center;
  width: calc(100% - 30px);
  padding: 10px 3%;
  background: #fff;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
 
.preview-box img {
  max-height: 80px;
  max-width: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
 
.preview-box .name {
  font-style: italic;
  color: #666;
  margin-right: 7px;
}
 
.preview-box .percent {
  font-weight: 600;
}
 
.preview-box .status {
  margin-left: 7px;
  font-weight: 600;
  text-transform: uppercase;
}