@import url(https://cdn.syncfusion.com/ej2/material.css);
.timer-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.timer {
  font-family: Poppins,Helvetica,sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 300px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
.dropzone{
    margin-bottom: 15px;
}

.img_preview {
    vertical-align: middle;
    height: 98px;
    margin-top: 20px;
}
.preview-box {
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
          align-items:center;
  width: calc(100% - 30px);
  padding: 10px 3%;
  background: #fff;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
 
.preview-box img {
  max-height: 80px;
  max-width: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
 
.preview-box .name {
  font-style: italic;
  color: #666;
  margin-right: 7px;
}
 
.preview-box .percent {
  font-weight: 600;
}
 
.preview-box .status {
  margin-left: 7px;
  font-weight: 600;
  text-transform: uppercase;
}

.timer-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.timer {
  font-family: Poppins,Helvetica,sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 300px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
